import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import UserPopover from '~/components/user-popover/view.jsx';
import Header from '../header/view.jsx';
import { summaryText } from '~/utils/html';
export default (({
  title,
  items,
  primaryKey,
  dataName,
  loading,
  url,
  actions
}) => h("div", {
  class: "items-container"
}, h("div", {
  class: "items mdui-card"
}, h(Header, {
  title: title,
  url: url
}), h("div", {
  class: "content"
}, h(Loading, {
  show: loading
}), items.map(item => h("div", {
  class: "items_item",
  style: {
    display: item.hide ? 'none' : 'block'
  }
}, h(Link, {
  class: "item",
  to: fullPath(`${url.split('#')[0]}/${item[primaryKey]}`),
  onclick: () => actions.afterItemClick(item)
}, h(UserPopover, {
  actions: actions,
  user: item.relationships.user,
  dataName: dataName,
  primaryKey: primaryKey,
  primaryValue: item[primaryKey]
}, h("div", {
  class: "avatar user-popover-trigger",
  style: {
    backgroundImage: `url("${item.relationships.user.avatar.middle}")`
  }
})), h("div", {
  class: "title mdui-text-color-theme-text",
  oncreate: summaryText(item.title),
  onupdate: summaryText(item.title)
})), h("button", {
  class: "shield",
  onclick: () => actions.shield(item)
}, "\u5C4F\u853D")))))));